/* eslint-disable react/jsx-fragments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Collapse, Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiAvatar from "components/SuiAvatar";
import SuiCover from "components/SuiCover";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import rgba from "assets/theme/functions/rgba";

function CollapsibleTable({ columns, rows, collapseElement }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [id, element] = collapseElement();

  const renderColumns = columns.map(({ name, align, width, title }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {title}
      </SuiBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `collapsible-row-${key}`;

    const tableRow = columns.map(({ name, align, fontWeight }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
              <SuiBox mr={2}>
                <SuiCover src={row[name][0]} name={row[name][1]} variant="square" size="sm" />
              </SuiBox>
              <SuiTypography
                variant="button"
                fontWeight={fontWeight ?? "regular"}
                sx={{ width: "max-content" }}
              >
                {row[name][1]}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        );
      } else if (typeof row[name] === "string") {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiTypography
              variant="button"
              fontWeight={fontWeight ?? "regular"}
              color="secondary"
              sx={{
                display: "inline-block",
                width: "max-content",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                wordWrap: "break-word",
              }}
            >
              {row[name]}
            </SuiTypography>
          </SuiBox>
        );
      } else {
        template = (
          <SuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SuiBox
              display="flex"
              justifyContent={align === "left" ? "start" : align}
              alignItems="center"
              py={0.5}
            >
              {row[name]}
            </SuiBox>
          </SuiBox>
        );
      }

      return template;
    });

    return row.id === id ? (
      <React.Fragment key={rowKey}>
        <TableRow
          selected
          key={rowKey}
          sx={{
            "&.Mui-selected": { backgroundColor: "rgba(103, 190, 236, 0.12)" },
            "&.Mui-selected:hover": { backgroundColor: "rgba(103, 190, 236, 0.16)" },
          }}
        >
          {tableRow}
        </TableRow>
        <TableRow key={uuidv4()}>
          <SuiBox
            key={uuidv4()}
            component="td"
            colSpan={columns.length}
            p={0}
            textAlign="left"
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <Collapse in timeout="auto" unmountOnExit>
              {element}
            </Collapse>
          </SuiBox>
        </TableRow>
      </React.Fragment>
    ) : (
      <TableRow key={rowKey}>{tableRow}</TableRow>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <SuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SuiBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of CollapsibleTable
CollapsibleTable.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the CollapsibleTable
CollapsibleTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  collapseElement: PropTypes.func,
};

export default CollapsibleTable;

// import * as React from "react";
// // import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
// import Collapse from "@mui/material/Collapse";
// import IconButton from "@mui/material/IconButton";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// function Row(props) {
//   const { row } = props;
//   const [open, setOpen] = React.useState(false);

//   return (
//     <>
//       <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
//         <TableCell>
//           <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </IconButton>
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {row.book.title}
//         </TableCell>
//         <TableCell align="right">{row.creationDate}</TableCell>
//         <TableCell align="right">{row.dueDate}</TableCell>
//         <TableCell align="right">{row.read}</TableCell>
//         <TableCell align="right">{row.listen}</TableCell>
//         <TableCell align="right">{row.record}</TableCell>
//         <TableCell align="right">{row.quiz}</TableCell>
//         <TableCell align="right">{row.material}</TableCell>
//         <TableCell align="right">{row.message}</TableCell>
//         <TableCell align="right">EDIT / DELETE</TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box sx={{ margin: 1 }}>
//               <Typography variant="h6" gutterBottom component="div">
//                 Detay
//               </Typography>
//               <Table size="small" aria-label="purchases">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>İsim</TableCell>
//                     <TableCell>Okuma</TableCell>
//                     <TableCell align="right">Dinleme</TableCell>
//                     <TableCell align="right">Ses kayıt</TableCell>
//                     <TableCell align="right">Quiz</TableCell>
//                     <TableCell align="right">Materyal</TableCell>
//                     <TableCell align="right">Geri Bildirim</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {row.details.map((assignmentDetail) => (
//                     <TableRow key={assignmentDetail.id}>
//                       <TableCell component="th" scope="row">
//                         details.name
//                       </TableCell>
//                       <TableCell>Okuma</TableCell>
//                       <TableCell align="right">Dinleme</TableCell>
//                       <TableCell align="right">Ses Kayıt</TableCell>
//                       <TableCell align="right">Quiz</TableCell>
//                       <TableCell align="right">Materyal</TableCell>
//                       <TableCell align="right">Geri </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </>
//   );
// }

// // Row.propTypes = {
// //   row: PropTypes.shape({
// //     id: PropTypes.number.isRequired,
// //     creationDate: PropTypes.string.isRequired,
// //     dueDate: PropTypes.string.isRequired,
// //     book: PropTypes.shape({
// //       id: PropTypes.number.isRequired,
// //       title: PropTypes.string.isRequired,
// //       coverImage: PropTypes.string.isRequired,
// //     }),
// //     read: PropTypes.bool,
// //     listen: PropTypes.bool,
// //     record: PropTypes.bool,
// //     quiz: PropTypes.bool,
// //     material: PropTypes.bool,
// //     message: PropTypes.string.isRequired,
// //     details: PropTypes.arrayOf(
// //       PropTypes.shape({
// //         id: PropTypes.number.isRequired,
// //         name: PropTypes.string.isRequired,
// //         email: PropTypes.string.isRequired,
// //         avatarUrl: PropTypes.string,
// //         feedbackMessage: PropTypes.shape({
// //           id: PropTypes.number.isRequired,
// //           createdAt: PropTypes.string.isRequired,
// //           updatedAt: PropTypes.string.isRequired,
// //           content: PropTypes.string.isRequired,
// //         }),
// //         task: PropTypes.arrayOf(
// //           PropTypes.shape({
// //             id: PropTypes.number.isRequired,
// //             completed: PropTypes.bool.isRequired,
// //           })
// //         ),
// //       })
// //     ).isRequired,
// //   }).isRequired,
// // };

// export default function CollapsibleTable() {
//   return (
//     <TableContainer component={Paper}>
//       <Table aria-label="collapsible table">
//         <TableHead>
//           <TableRow>
//             <TableCell />
//             <TableCell align="left">KİTAP</TableCell>
//             <TableCell align="left">OLUŞTURULMA ZAMANI</TableCell>
//             <TableCell align="left">BİTİŞ ZAMANI</TableCell>
//             <TableCell align="center">OKUMA</TableCell>
//             <TableCell align="center">DİNLEME</TableCell>
//             <TableCell align="center">SES KAYIT</TableCell>
//             <TableCell align="center">QUIZ</TableCell>
//             <TableCell align="center">MATERYAL</TableCell>
//             <TableCell align="center">MESAJ</TableCell>
//             <TableCell align="center"> </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {allAssignments.map((row) => (
//             <Row key={row.name} row={row} />
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }
