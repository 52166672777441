/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CheckCircle, Pause, Play } from "phosphor-react";
import { Howl } from "howler";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { connect } from "react-redux";

const RecordTableCell = ({ audioUrl, localizations }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const newSound = new Howl({
      src: audioUrl,
      preload: true,
      onload: () => {
        setLoading(false);
      },
    });

    newSound.once("end", () => {
      setIsPlaying(false);
      setAudio(null);
    });
    setAudio(newSound);
  }, [audioUrl]);

  useEffect(() => {
    if (isPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [isPlaying, audio]);

  return (
    <SuiBox display="flex" alignItems="center">
      <CheckCircle size={28} color="#82D616" weight="duotone" />
      <SuiBox
        pl={1}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (!isPlaying) {
            if (!audio) {
              setLoading(true);
              const newSound = new Howl({
                src: audioUrl,
                preload: true,
                onload: () => {
                  setLoading(false);
                },
              });

              newSound.once("end", () => {
                setIsPlaying(false);
                setAudio(null);
              });
              setAudio(newSound);
            }
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
          }
        }}
      >
        <SuiTypography
          variant="button"
          color="secondary"
          sx={{ display: "inline-block", width: "max-content" }}
        >
          {isPlaying
            ? loading
              ? "Yükleniyor..."
              : localizations?.PAUSE_BTN ?? <Pause size={28} weight="duotone" />
            : localizations?.PLAY_BTN ?? <Play size={28} weight="duotone" />}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

RecordTableCell.defaultProps = {
  localizations: null,
  audioUrl: null,
};

RecordTableCell.propTypes = {
  localizations: PropTypes.objectOf(PropTypes.any),
  audioUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(RecordTableCell);
