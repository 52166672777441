import { PAGES } from "redux/actions/pages/constants";

// feature name
export const HOME = `${PAGES} [Home]`;

// action types
export const START_HOME_PAGE = `${HOME} START`;
export const GET_HOME_ASSIGNMENT_DETAILS = `${HOME} GET_HOME_ASSIGNMENT_DETAILS`;

// action creators
export const startHomePage = () => ({
  type: START_HOME_PAGE,
});

export const getHomeAssignmentDetails = ({ assignmentId }) => ({
  type: GET_HOME_ASSIGNMENT_DETAILS,
  payload: { assignmentId },
});
